.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dungeonDisplay {
	background-color: #ccc;
	flex-grow: 1;

	display: flex;
	overflow: auto;
}

.dungeonDisplay > canvas {
	margin: auto;
	background-color: #aaa;
	border: solid 0.5em #666;
	border-radius: 0.5em;
}