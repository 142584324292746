.menu {
	background-color: lightgray;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    min-height: 2em;
    padding: 0 0.5em;
}

.menu__link, .menu__button {
    margin: 0 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.menu__group, .menu__spacer {
    flex-grow: 1;
}

.menu__group > * + * {
    margin-left: 1em;
}

.menu__choice {
    display: flex;
    align-self: flex-start;
    align-items: stretch;
    list-style: none;
    margin: 0.25em;
    padding: 0;
    border: solid grey 1px;
    border-radius: 0.25em;
}

.menu__selector {
    margin: 0;
    padding: 0.25em;
}

.menu__selector:not(:first-child) {
    border-left: solid grey 1px;
}

.menu__selector--active {
    color: white;
}

.menu__selector:not(.menu__selector--active) {
    cursor: pointer;
}

.menu__section {
    padding: 0 1em;
    display: flex;
    align-items: center;
}